
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "itemDepth13120957943",
      {"classes":{"root":"itemDepth13120957943__root","container":"itemDepth13120957943__container","label":"itemDepth13120957943__label","itemWrapper":"itemDepth13120957943__itemWrapper","list":"itemDepth13120957943__list","positionBox":"itemDepth13120957943__positionBox","animationBox":"itemDepth13120957943__animationBox"},"vars":{"translate-x":"--itemDepth13120957943-translate-x"},"stVars":{"subsubmenu-box-opacity":"var(--subsubmenu-box-opacity)","subsubmenu-box-pointer-events":"var(--subsubmenu-box-pointer-events)","subsubmenu-box-position":"var(--subsubmenu-box-position)","subsubmenu-box-show-animation":"var(--subsubmenu-box-show-animation)","subsubmenu-box-left":"var(--subsubmenu-box-left)","subsubmenu-box-right":"var(--subsubmenu-box-right)","subsubmenu-box-translate-x-coef":"var(--subsubmenu-box-translate-x-coef)","white-space":"var(--white-space)","label-word-wrap":"var(--label-word-wrap)","columns-count":"var(--columns-count)"},"keyframes":{"expandSubmenu":"itemDepth13120957943__expandSubmenu"}},
      "",
      -1,
      module.id
  );
  