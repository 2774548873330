import React, { useCallback } from 'react';
import StylableHorizontalMenu from '../../StylableHorizontalMenu';
import { IStylableHorizontalMenuUITypeProps } from '../../../StylableHorizontalMenu.types';
import { MenuWrapperWrappable } from '../../components/MenuWrapperWrappable/MenuWrapperWrappable';
import { getClasses_column } from '../../components/MenuItem/styles/getClasses_column';
import { getHasHeadings_column } from '../../utils/getHasHeadings_column';
import { depth0PositionUpdaters__wrapColumn } from '../../components/MenuItem/positionUpdaters/itemDepth0_wrapColumn';
import { createInjectCssVars_column } from '../../components/MenuItem/styles/injectCssVars_column';

const StylableHorizontalMenu_WrapColumn: React.FC<IStylableHorizontalMenuUITypeProps> =
  props => (
    <StylableHorizontalMenu
      {...props}
      menuWrapper={MenuWrapperWrappable}
      getMenuItemClasses={getClasses_column}
      positionUpdaters={[depth0PositionUpdaters__wrapColumn]}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      injectCssVars={useCallback(createInjectCssVars_column(props.className), [
        props.className,
      ])}
      getHasHeadings={getHasHeadings_column}
      isColumnStretched={props.submenuMode === 'columnStretched'}
      // TODO: remove
      menuMode="wrap"
    />
  );

export default StylableHorizontalMenu_WrapColumn;
