import * as React from 'react';
import { IStylableHorizontalMenuProps } from '../StylableHorizontalMenu.types';
import { MenuItem } from './components/MenuItem/MenuItem';
import { st, classes } from './StylableHorizontalMenu.component.st.css';

const StylableHorizontalMenu: React.FC<IStylableHorizontalMenuProps> = ({
  id,
  items,
  isHeightAuto,
  positionUpdaters,
  getMenuItemClasses,
  injectCssVars,
  getHasHeadings,
  menuWrapper: MenuWrapper,
  className: stylableClassName,
  currentPageHref,
  menuMode,
  submenuMode,
  width,
  style,
}: IStylableHorizontalMenuProps) => {
  // TODO: split styles into ui-types
  const className = st(
    classes.root,
    {
      menuMode,
    },
    stylableClassName,
  );

  return (
    <div id={id} style={{ ...style, ...(isHeightAuto && { height: 'auto' }) }}>
      <MenuWrapper
        className={className}
        items={items}
        currentPageHref={currentPageHref}
        width={width}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            depth={0}
            item={item}
            currentPageHref={currentPageHref}
            className={classes.menuItem}
            isColumnStretched={submenuMode === 'columnStretched'}
            hasHeadings={!!getHasHeadings && getHasHeadings(item)}
            getMenuItemClasses={getMenuItemClasses}
            injectCssVars={injectCssVars}
            positionUpdaters={positionUpdaters}
          />
        ))}
      </MenuWrapper>
    </div>
  );
};

export default StylableHorizontalMenu;
