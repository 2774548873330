import { PositionUpdaters } from './types';

export const depth0PositionUpdaters__wrapColumn: PositionUpdaters = {
  onEnter: ({ label, positionBox }) => {
    const marginBottom = window.getComputedStyle(label).marginBottom;
    positionBox.style.marginTop = `-${marginBottom}`;
  },
  onLeave: ({ positionBox }) => {
    positionBox.removeAttribute('style');
  },
};
