import * as React from 'react';
import { useDidMount } from '../../../../providers/useDidMount';
import { throttle } from '../../../../core/commons/utils';

const getNodeHorizontalOffsets = (nodeRef: React.RefObject<HTMLElement>) => {
  const { current: node } = nodeRef;
  if (node) {
    const { left: navLeft, width: menuWidth } = node.getBoundingClientRect();

    const documentWidth = document.documentElement.clientWidth;
    const menuRight = documentWidth - navLeft - menuWidth;

    return {
      left: navLeft,
      right: menuRight,
    };
  }
  return { left: 0, right: 0 };
};

const getCSSVars = ({ left, right }: { left: number; right: number }) => {
  return {
    '--positionBox-offset-left': `${left || 0}`,
    '--positionBox-offset-right': `${right || 0}`,
  };
};

export const useSubmenuOffsetVars = (nodeRef: React.RefObject<HTMLElement>) => {
  const [horizontalOffsets, setHorizontalOffsets] = React.useState({
    left: 0,
    right: 0,
  });
  function updateOffsets() {
    setHorizontalOffsets(getNodeHorizontalOffsets(nodeRef));
  }
  useDidMount(() => {
    const throttledFunc = throttle(updateOffsets, 300);
    throttledFunc();
    window?.addEventListener('resize', throttledFunc);
    return () => {
      window?.removeEventListener('resize', throttledFunc);
    };
  });
  return getCSSVars(horizontalOffsets);
};
