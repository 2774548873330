
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "StylableHorizontalMenu2591252333",
      {"classes":{"root":"StylableHorizontalMenu2591252333__root","scrollButton":"StylableHorizontalMenu2591252333__scrollButton","menu":"StylableHorizontalMenu2591252333__menu","menuItem":"StylableHorizontalMenu2591252333__menuItem","columnsLayout":"StylableHorizontalMenu2591252333__columnsLayout","positionBox":"StylableHorizontalMenu2591252333__positionBox"},"vars":{},"stVars":{"wrap":"var(--menu-flex-wrap, wrap)"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  