
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "submenu2714210453",
      {"classes":{"root":"submenu2714210453__root","menuItem":"submenu2714210453__menuItem","heading":"submenu2714210453__heading","listWrapper":"submenu2714210453__listWrapper","pageWrapper":"submenu2714210453__pageWrapper","pageStretchWrapper":"submenu2714210453__pageStretchWrapper","overrideWidth":"submenu2714210453__overrideWidth","rowItem":"submenu2714210453__rowItem"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  