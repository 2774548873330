import * as React from 'react';
import { IMenuWrapperProps } from '../../../StylableHorizontalMenu.types';
import { classes } from '../../StylableHorizontalMenu.component.st.css';
import { useSubmenuOffsetVars } from '../../hooks/useSubmenuOffsetVars';

export const MenuWrapperWrappable: React.FC<IMenuWrapperProps> = ({
  children,
  className,
}) => {
  const menuContainerRef = React.useRef<HTMLElement>(null);
  const horizontalOffsets = useSubmenuOffsetVars(menuContainerRef);

  return (
    <nav
      className={className}
      aria-label="Site"
      ref={menuContainerRef}
      style={horizontalOffsets as React.CSSProperties}
    >
      <ul className={classes.menu}>{children}</ul>
    </nav>
  );
};
