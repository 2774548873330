
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "itemDepth01301543341",
      {"classes":{"root":"itemDepth01301543341__root","container":"itemDepth01301543341__container","label":"itemDepth01301543341__label","itemWrapper":"itemDepth01301543341__itemWrapper","positionBox":"itemDepth01301543341__positionBox","animationBox":"itemDepth01301543341__animationBox","alignBox":"itemDepth01301543341__alignBox","list":"itemDepth01301543341__list"},"vars":{"animation-duration":"--itemDepth01301543341-animation-duration"},"stVars":{"submenu-box-position":"var(--submenu-box-position)","submenu-box-left":"var(--submenu-box-left)","columns-count":"var(--columnsAmount)","horizontal-spacing":"var(--horizontalSpacing)","white-space":"var(--white-space)","submenu-box-right":"var(--submenu-box-right)","submenu-box-max-width":"var(--submenu-box-max-width)","submenu-box-offset-left":"var(--positionBox-offset-left)","submenu-box-offset-right":"var(--positionBox-offset-right)","submenu-box-margin":"var(--submenu-box-margin)"},"keyframes":{"expandSubmenu":"itemDepth01301543341__expandSubmenu","removeOverflow":"itemDepth01301543341__removeOverflow"}},
      "",
      -1,
      module.id
  );
  