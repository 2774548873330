import { classes as rootClasses } from '../../../StylableHorizontalMenu.component.st.css';
import style0 from './itemDepth0.st.css';
import style1 from './itemDepth1.st.css';
import { classes as submenuClasses, st as submenuSt } from './submenu.st.css';
import { GetMenuItemClassesFunc } from './types';

const depthStyleMap = [style0, style1, style1];

export const getClasses_column: GetMenuItemClassesFunc = ({
  depth,
  isColumnStretched = false,
  isHovered,
  isCurrentPage,
  hasHeadings,
  className: parentClassName,
}) => {
  const { st, classes } = depthStyleMap[depth] || style0;

  if (depth === 0) {
    return {
      root: st(classes.root, { isHovered, isCurrentPage }, parentClassName),
      itemWrapper: classes.itemWrapper,
      container: classes.container,
      label: classes.label,
      positionBox: st(
        classes.positionBox,
        {
          isColumnStretched,
        },
        // root::positionBox defines left and right margins to stretched submenu
        isColumnStretched ? rootClasses.positionBox : '',
      ),
      animationBox: submenuSt(
        submenuClasses.root,
        // .root::columnsLayout - selector for stylable panel
        rootClasses.columnsLayout,
        classes.animationBox,
      ),
      alignBox: st(
        classes.alignBox,
        submenuClasses.pageWrapper,
        isColumnStretched
          ? submenuClasses.pageStretchWrapper
          : submenuClasses.overrideWidth,
      ),
      list: st(classes.list, submenuClasses.listWrapper),
      // .root::columnsLayout::menuItem or .root::columnsLayout::heading
      subItem: hasHeadings ? submenuClasses.heading : submenuClasses.menuItem,
    };
  }

  return {
    root: st(classes.root, { isHovered, isCurrentPage }, parentClassName),
    itemWrapper: classes.itemWrapper,
    hasSubItems: submenuClasses.rowItem,
    container: classes.container,
    label: classes.label,
    positionBox: classes.positionBox,
    alignBox: classes.alignBox,
    list: classes.list,
    subItem: submenuClasses.menuItem,
  };
};
