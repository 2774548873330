import React, { memo, useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import Link from '../../../../Link/viewer/Link';
import { IMenuItemProps } from '../../../StylableHorizontalMenu.types';
import { testIds } from '../../testIds';
import { getIsCurrentPage } from '../../utils/getIsCurrentPage';

const createEventListeners = (setIsHovered: (value: boolean) => void) => {
  const onEnter = () => setIsHovered(true);
  const onLeave = () => setIsHovered(false);
  return {
    onMouseEnter: onEnter,
    onMouseLeave: onLeave,
    onFocusCapture: onEnter,
    onBlurCapture: onLeave,
  };
};

export const MenuItem: React.FC<IMenuItemProps> = memo(
  ({
    item,
    depth = 0,
    isColumnStretched,
    currentPageHref,
    className,
    hasHeadings,
    positionUpdaters,
    getMenuItemClasses,
    injectCssVars,
  }) => {
    const { link, label, items, forceHovered = false } = item;
    const [isHovered, setIsHovered] = useState(false);
    const labelRef = useRef<HTMLAnchorElement | HTMLDivElement | null>(null);
    const positionBoxRef = useRef<HTMLDivElement | null>(null);
    const isCurrentPage = getIsCurrentPage(link, currentPageHref);
    // heading should listen events only inside label
    const isHeading = hasHeadings && depth === 1;
    const eventListeners = createEventListeners(setIsHovered);

    const classes = getMenuItemClasses({
      depth,
      isHovered,
      isCurrentPage,
      isColumnStretched,
      hasHeadings,
      className,
    });
    // inject CSS vars from stylable panel
    const cssVars = injectCssVars?.(depth);

    useEffect(() => {
      if (!labelRef.current || !positionBoxRef.current) {
        return;
      }
      positionUpdaters[depth]?.[isHovered ? 'onEnter' : 'onLeave']({
        label: labelRef.current,
        positionBox: positionBoxRef.current,
      });
    }, [isHovered, depth, positionUpdaters, item]);

    useEffect(() => {
      setIsHovered(forceHovered);
    }, [forceHovered]);

    return (
      <li
        className={cn(
          classes.itemWrapper,
          !!items?.length && classes.hasSubItems,
        )}
        data-testid={testIds.menuItem(depth)}
        data-item-depth={depth}
        data-is-current={isCurrentPage}
        {...(isHovered && {
          'data-hovered': true,
        })}
        {...(!isHeading && eventListeners)}
      >
        <Link
          {...link}
          {...(isHeading && eventListeners)}
          className={classes.root}
          ref={labelRef}
        >
          <div className={classes.container}>
            <span className={classes.label}>{label}</span>
          </div>
        </Link>
        {items && Boolean(items.length) && (
          <div
            className={classes.positionBox}
            ref={positionBoxRef}
            role="region"
            aria-label={label}
            data-testid={testIds.positionBox}
          >
            <div className={classes.animationBox} style={cssVars?.animationBox}>
              <div className={classes.alignBox}>
                <ul className={classes.list} style={cssVars?.list}>
                  {items.map((subItem, i) => (
                    <MenuItem
                      key={i}
                      item={subItem}
                      currentPageHref={currentPageHref}
                      depth={depth + 1}
                      className={classes.subItem}
                      hasHeadings={hasHeadings}
                      positionUpdaters={positionUpdaters}
                      getMenuItemClasses={getMenuItemClasses}
                      injectCssVars={injectCssVars}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </li>
    );
  },
);
